<script>
import vSelect from 'vue-select'
import { useVuelidate } from '@vuelidate/core'
import {
  required, requiredIf, sameAs,
} from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: { vSelect },
  validations() {
    return {
      createUserForm: {
        merchant_type: { required },
        email: { required },
        mobile_number: { required },
        onboarding_origin_reference_id: { required },
        password: { required },
        confirm_password: { sameAs: sameAs(this.createUserForm.password) },
      },
      referrerForm: {
        selectedReferrer: { required: requiredIf(this.createUserForm.merchant_type === 3) },
      },
    }
  },
  data() {
    return {
      createUserForm: {
        merchant_type: 2,
        email: '',
        mobile_number: '',
        onboarding_origin_reference_id: 1,
        password: '',
        confirm_password: '',
      },
      referrerForm: {
        selectedReferrer: '',
        merchant_id: '',
        affiliate_account_uuid: '',
        affiliate_code: '',
      },
    }
  },
  computed: {
    referrerMerchantListOpts() {
      const list = this.$store.getters['onboardingv2/getReferrerMerchantListOpts']
      return list
    },
    merchantTypeOpts() {
      return [
        { name: 'Master Merchant', value: 1 },
        { name: 'Merchant', value: 2 },
        { name: 'Referrer', value: 3 },
      ]
    },
    merchantOriginOpts() {
      return [
        { name: 'Local', value: 1 },
        { name: 'International', value: 2 },
      ]
    },
  },
  async mounted() {
    await this.getReferrerMerchantList()
  },
  methods: {
    async getReferrerMerchantList() {
      const payload = {
        length: 1000,
        start: 0,
        filter_array_objects: JSON.stringify([
          {
            filter_column: 'created_at',
            filter_start: '1945-02-01',
            filter_end: '2045-02-27',
          },
          {
            filter_column: 'keyword',
            filter_value: '',
          },
        ]),
      }
      await this.$store.dispatch('onboardingv2/getReferrerMerchantList', payload)
    },
    async registerNewMerchant() {
      const valid = await this.v$.createUserForm.$validate()
      // alert(valid)
      if (!valid) return

      const referrerValid = await this.v$.referrerForm.$validate()
      if (!referrerValid) return

      if (this.createUserForm.merchant_type === 3) {
        const referrerPayload = {
          ...this.createUserForm,
          ...this.referrerForm,
          merchant_type_id: this.createUserForm.merchant_type,
        }
        await this.$store.dispatch('onboardingv2/createMamMerchantUserReferralByAdmin', referrerPayload).then(resp => {
          if (resp.data.response_code === 2100) {
            this.$swal.fire({
              title: 'Successfully Created',
              text: 'Merchant User has been created',
              icon: 'success',
            })

            this.$emit('register-success', true)
          } else {
            this.$swal.fire({
              title: `Failed ${resp.data.response_code}`,
              text: resp.data.description,
              icon: 'error',
            })

            this.$emit('register-success', false)
          }
        })
      } else {
        const payload = {
          ...this.createUserForm,
          merchant_type_id: this.createUserForm.merchant_type,
        }
        await this.$store.dispatch('onboardingv2/createMamMerchantUser', payload).then(resp => {
          if (resp.data.response_code === 2100) {
            this.$swal.fire({
              title: 'Successfully Created',
              text: 'Merchant User has been created',
              icon: 'success',
            })

            this.$emit('register-success', true)
          } else {
            this.$swal.fire({
              title: `Failed ${resp.data.response_code}`,
              text: resp.data.description,
              icon: 'error',
            })

            this.$emit('register-success', false)
          }
        })
      }

      // alert('FORM registerNewMerchant')
    },
    onMerchantTypeSelected(e) {
      // alert(e)
      this.createUserForm.merchant_type = e
    },
    onMerchantMerchantOriginSelected(e) {
      // alert(e)
    },
    onSelectReferrer(e) {
      // alert(e)
      this.referrerForm.affiliate_account_uuid = e.affiliate_uuid
      this.referrerForm.affiliate_code = e.affiliate_code
      this.referrerForm.merchant_id = e.merchant_id
    },
  },
}
</script>

<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-form-group
          :class="[{ 'has-error': v$.createUserForm.merchant_type.$error }]"
        >
          <label>Merchant Type</label>
          <v-select
            key="value"
            v-model="createUserForm.merchant_type"
            :class="[{'is-invalid': v$.createUserForm.merchant_type.$errors.length > 0 }]"
            :reduce="merchant_type => merchant_type.value"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            placeholder="Please select merchant type"
            :options="merchantTypeOpts"
            @input="onMerchantTypeSelected($event)"
          />
          <template v-for="(error, idx) in v$.createUserForm.merchant_type.$errors">
            <p
              :key="'error-'+idx"
              class="small text-danger"
            >
              {{ error.$message }}
            </p>
          </template>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          :class="[{ 'has-error': v$.createUserForm.email.$error }]"
        >
          <label>Email</label>
          <b-form-input
            v-model="createUserForm.email"
            size="sm"
            :class="[{'is-invalid': v$.createUserForm.email.$errors.length > 0 }]"
            placeholder="Email"
          />
          <template v-for="(error, idx) in v$.createUserForm.email.$errors">
            <p
              :key="'error-'+idx"
              class="small text-danger"
            >
              {{ error.$message }}
            </p>
          </template>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          :class="[{ 'has-error': v$.createUserForm.mobile_number.$error }]"
        >
          <label>Phone Number</label>
          <b-form-input
            v-model="createUserForm.mobile_number"
            :class="[{'is-invalid': v$.createUserForm.mobile_number.$errors.length > 0 }]"
            size="sm"
            placeholder="Phone Number"
          />
          <template v-for="(error, idx) in v$.createUserForm.mobile_number.$errors">
            <p
              :key="'error-'+idx"
              class="small text-danger"
            >
              {{ error.$message }}
            </p>
          </template>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          :class="[{ 'has-error': v$.createUserForm.password.$error }]"
        >
          <label>Password</label>
          <b-form-input
            v-model="createUserForm.password"
            type="password"
            size="sm"
            :class="[{'is-invalid': v$.createUserForm.password.$errors.length > 0 }]"
            placeholder="Password"
          />
          <template v-for="(error, idx) in v$.createUserForm.password.$errors">
            <p
              :key="'error-'+idx"
              class="small text-danger"
            >
              {{ error.$message }}
            </p>
          </template>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          :class="[{ 'has-error': v$.createUserForm.confirm_password.$error }]"
        >
          <label>Confirm Password</label>
          <b-form-input
            v-model="createUserForm.confirm_password"
            type="password"
            size="sm"
            :class="[{'is-invalid': v$.createUserForm.confirm_password.$errors.length > 0 }]"
            placeholder="Confirm Password"
          />
          <template v-for="(error, idx) in v$.createUserForm.confirm_password.$errors">
            <p
              :key="'error-'+idx"
              class="small text-danger"
            >
              {{ error.$message }}
            </p>
          </template>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          :class="[{ 'has-error': v$.createUserForm.onboarding_origin_reference_id.$error }]"
        >
          <label>Origin</label>
          <v-select
            key="value"

            v-model="createUserForm.onboarding_origin_reference_id"
            :class="[{'is-invalid': v$.createUserForm.onboarding_origin_reference_id.$errors.length > 0 }]"
            :reduce="onboarding_origin_reference_id => onboarding_origin_reference_id.value"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            placeholder="Please select origin"
            :options="merchantOriginOpts"
            @input="onMerchantMerchantOriginSelected($event)"
          />
          <template v-for="(error, idx) in v$.createUserForm.onboarding_origin_reference_id.$errors">
            <p
              :key="'error-'+idx"
              class="small text-danger"
            >
              {{ error.$message }}
            </p>
          </template>
        </b-form-group>
      </b-col>

      <template v-if="createUserForm.merchant_type === 3">
        <b-col cols="12">
          <b-form-group
            :class="[{ 'has-error': v$.referrerForm.selectedReferrer.$silentErrors.length > 0 }]"
          >
            <!--            {{ referrerForm.selectedReferrer }}-->
            <label>Referrer</label>
            <v-select
              key="value"

              v-model="referrerForm.selectedReferrer"
              :class="[{'is-invalid': v$.referrerForm.selectedReferrer.$silentErrors.length > 0 }]"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              return-object
              placeholder="Please select referrer"
              :options="referrerMerchantListOpts"
              @input="onSelectReferrer($event)"
            />
            <template v-for="(error, idx) in v$.referrerForm.selectedReferrer.$silentErrors">
              <p
                :key="'error-'+idx"
                class="small text-danger"
              >
                {{ error.$message }}
              </p>
            </template>
          </b-form-group>
        </b-col>
      </template>

    </b-row>
  </div>
</template>
