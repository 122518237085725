<template>
  <div>
    <b-alert
      v-if="showMessage && messageResponse.errorCode === 2100"
      variant="success"
      class="p-1"
      show
    >

      {{ messageResponse.message }}
    </b-alert>
    <b-alert
      v-if="showMessage && messageResponse.errorCode !== 2100"
      variant="danger"
      class="p-1"
      show
    >
      {{ messageResponse.message }}
    </b-alert>
    <b-row>
      <b-col v-if="!collapseMode">
        <div
          v-for="(checklistItem, idx) in rowData.onboardingChecklist"
          :key="`checklistItem-${idx}`"
          class="section-checklist"
        >
          <div
            v-if="checklistItem.onboarding_checklist_id === 1"
          >
            <b-row>
              <b-col cols="12">
                <b-form-group>
                  <label>Email</label>
                  <div class="d-flex">
                    <b-form-input
                      v-model="rowData.email"
                      size="sm"
                      disabled
                      placeholder="Email"
                    />

                    <b-button
                      class="ml-1"
                      :disabled="emailVerified"
                      size="sm"
                      :variant="emailVerified ? 'success' : 'info'"
                      @click="sendVerificationEmail"
                    >
                      <template v-if="emailVerified">
                        Verified
                      </template>
                      <template v-else>
                        Send Verification Email
                      </template>
                    </b-button>
                  </div>
                </b-form-group>

              </b-col>
            </b-row>
          </div>
          <div
            v-if="checklistItem.onboarding_checklist_id === 2"
          >
            <b-row>
              <b-col cols="12">
                <b-form-group>
                  <label>Plan</label>
                  <v-select
                    v-model="rowData.plan"
                    :reduce="plan => plan.value"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :disabled="!isEditing"
                    placeholder="Please select"
                    :options="planList"
                  >
                    <!--                    <template v-slot:option="productOfferingList">-->
                    <!--                      {{ productOfferingList.value }}-->
                    <!--                    </template>-->
                  </v-select>

                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div
            v-if="checklistItem.onboarding_checklist_id === 3"
          >
            <!--            <pre>{{ productOfferingList }}</pre>-->

            <b-row>
              <b-col cols="12">
                <b-form-group>
                  <label>Product Offering List</label>
                  <v-select
                    v-model="selectedOnboardingProductOfferingList"
                    class="product-offering-vselect"
                    multiple
                    :disabled="!isEditing"
                    :reduce="onboarding_product_offering_list => onboarding_product_offering_list.value"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    placeholder="Please select"
                    :options="productOfferingList"
                  >
                    <!--                    <template v-slot:option="productOfferingList">-->
                    <!--                      {{ productOfferingList.value }}-->
                    <!--                    </template>-->
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>

          </div>
          <div
            v-if="checklistItem.onboarding_checklist_id === 4"
            class="pt-1"
          >
            <b-row>
              <b-col
                cols="12"
                lg="4"
              >
                <b-form-group>
                  <label>Full Name</label>
                  <b-form-input
                    v-model="rowData.full_name"
                    size="sm"
                    :disabled="!isEditing"
                    placeholder="Full Name"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                lg="4"
              >
                <b-form-group>
                  <label>NRIC/Passport</label>
                  <b-form-input
                    v-model="rowData.signature_applicant_ic"
                    size="sm"
                    :disabled="!isEditing"
                    placeholder="NRIC/Passport"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                lg="4"
              >
                <b-form-group>
                  <label>PIC Name</label>
                  <b-form-input
                    v-model="rowData.pic_name"
                    size="sm"
                    :disabled="!isEditing"
                    placeholder="PIC Name"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                lg="6"
              >
                <b-form-group>
                  <label>Email</label>
                  <b-form-input
                    v-model="rowData.email"
                    size="sm"
                    disabled
                    placeholder="Email"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                lg="6"
              >
                <b-form-group>
                  <label>Phone Number</label>
                  <b-form-input
                    v-model="rowData.phone_number"
                    size="sm"
                    :disabled="!isEditing"
                    placeholder="Phone Number"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div
            v-if="checklistItem.onboarding_checklist_id === 5"
            class="pt-1"
          >
            <b-row>
              <b-col
                cols="12"
                lg="3"
              >
                <b-form-group>
                  <label>Company Name</label>
                  <b-form-input
                    v-model="rowData.company_name"
                    size="sm"
                    :disabled="!isEditing"
                    placeholder="Company Name"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                lg="3"
              >
                <b-form-group>
                  <label>Display Name</label>
                  <b-form-input
                    v-model="rowData.display_name"
                    size="sm"
                    :disabled="!isEditing"
                    placeholder="Display Name"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                lg="3"
              >
                <b-form-group>
                  <label>Company Registration No</label>
                  <b-form-input
                    v-model="rowData.company_registration_no"
                    size="sm"
                    :disabled="!isEditing"
                    placeholder="Company Registration No"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                lg="3"
              >
                <b-form-group>
                  <label>Company Contact No</label>
                  <b-form-input
                    v-model="rowData.company_contact_no"
                    size="sm"
                    :disabled="!isEditing"
                    placeholder="Company Contact No"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                lg="4"
              >
                <b-form-group>
                  <label>Industry</label>
                  <b-form-input
                    v-model="rowData.industry"
                    size="sm"
                    :disabled="!isEditing"
                    placeholder="Industry"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                lg="4"
              >
                <b-form-group>
                  <label>Paid Up Capital</label>
                  <b-form-input
                    v-model="rowData.paid_up_capital"
                    size="sm"
                    :disabled="!isEditing"
                    placeholder="Paid Up Capital"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                lg="4"
              >
                <b-form-group>
                  <label>Nature of Business</label>
                  <b-form-input
                    v-model="rowData.company_nature_of_business"
                    size="sm"
                    :disabled="!isEditing"
                    placeholder="Nature of Business"
                  />
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                lg="4"
              >
                <b-form-group>
                  <label>Description of Products</label>
                  <b-form-input
                    v-model="rowData.company_description_of_product"
                    size="sm"
                    :disabled="!isEditing"
                    placeholder="Description of Products"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                lg="4"
              >
                <b-form-group>
                  <label>Address Line 1</label>
                  <b-form-input
                    v-model="rowData.company_business_address_1"
                    size="sm"
                    :disabled="!isEditing"
                    placeholder="Address Line 1"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                lg="4"
              >
                <b-form-group>
                  <label>Address Line 2</label>
                  <b-form-input
                    v-model="rowData.company_business_address_2"
                    size="sm"
                    :disabled="!isEditing"
                    placeholder="Address Line 2"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="6"
                lg="3"
              >
                <b-form-group>
                  <label>Postcode</label>
                  <b-form-input
                    v-model="rowData.company_business_postcode"
                    size="sm"
                    :disabled="!isEditing"
                    placeholder="Postcode"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="6"
                lg="3"
              >
                <b-form-group>
                  <label>City</label>
                  <b-form-input
                    v-model="rowData.company_business_city"
                    size="sm"
                    :disabled="!isEditing"
                    placeholder="City"
                  />
                </b-form-group>
              </b-col>

              <b-col
                cols="6"
                lg="3"
              >
                <b-form-group>
                  <label>State</label>
                  <b-form-input
                    v-model="rowData.company_business_state"
                    size="sm"
                    :disabled="!isEditing"
                    placeholder="State"
                  />
                </b-form-group>
              </b-col>

              <b-col
                cols="6"
                lg="3"
              >
                <b-form-group>
                  <label>Country</label>
                  <b-form-input
                    v-model="rowData.company_business_country"
                    size="sm"
                    :disabled="!isEditing"
                    placeholder="Country"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div
            v-if="checklistItem.onboarding_checklist_id === 6"
            class="pt-1"
          >
            <h5 class="mb-1 font-weight-bolder">
              Bank Information Local
            </h5>
            <b-row>
              <template v-if="rowData.onboarding_origin_reference_id === 1 ">
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group>
                    <label>Bank Name</label>
                    <v-select
                      v-model="rowData.company_bank_name"
                      :reduce="payout_service_id => payout_service_id.name"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :disabled="!isEditing"
                      class="select-size-sm"
                      placeholder="Please select"
                      :options="PayoutServiceList"
                      @option:selected="populatePayoutServiceIdField($event)"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group>
                    <label>Bank Account No</label>

                    <b-input-group>
                      <b-form-input
                        v-model="rowData.company_bank_account_no"
                        size="sm"
                        :disabled="!isEditing"
                        placeholder="Bank Account No"
                      />
                      <template #append>
                        <b-button
                          size="sm"
                          @click="toggleCheckBankModal({third_party_account_no:rowData.company_bank_account_no, verification_channel_id: rowData.payout_service_id })"
                        >
                          CHECK
                        </b-button>
                      </template>
                    </b-input-group>

                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group>
                    <label>Bank Account Holder Name</label>
                    <b-form-input
                      v-model="rowData.company_bank_account_name"
                      size="sm"
                      :disabled="!isEditing"
                      placeholder="Bank Account Holder Name"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="6">
                  <b-form-group>
                    <label>Payout Service ID</label>
                    <b-form-input
                      v-model="rowData.payout_service_id"
                      size="sm"
                      :disabled="!isEditing"
                      placeholder="Payout Service ID"
                    />
                  </b-form-group>
                </b-col>
              </template>
            </b-row>
          </div>
          <div
            v-if="checklistItem.onboarding_checklist_id === 7"
            class="pt-1"
          >
            <b-row>

              <template v-if="rowData.onboarding_origin_reference_id === 2 ">
                <b-col cols="12">
                  <b-form-group>
                    <label>DUNS</label>
                    <b-form-input
                      v-model="rowData.duns_no"
                      size="sm"
                      :disabled="!isEditing"
                      placeholder="DUNS"
                    />
                  </b-form-group>
                </b-col>
              </template>

            </b-row>

          </div>
          <div
            v-if="checklistItem.onboarding_checklist_id === 13"
            class="pt-1"
          >
            <h5 class="mb-1 font-weight-bolder">
              Bank Information (International)
            </h5>
            <b-row>
              <template v-if="rowData.onboarding_origin_reference_id === 2 ">
                <b-col
                  cols="12"
                  lg="4"
                >
                  <b-form-group>
                    <label>Bank Name</label>
                    <b-form-input
                      v-model="rowData.overseas_bank_account_name"
                      size="sm"
                      :disabled="!isEditing"
                      placeholder="Bank Name"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  lg="4"
                >
                  <b-form-group>
                    <label>SWIFT Code</label>
                    <b-form-input
                      v-model="rowData.overseas_bank_swift_code"
                      size="sm"
                      :disabled="!isEditing"
                      placeholder="SWIFT Code"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="4"
                >
                  <b-form-group>
                    <label>Account Holder Name</label>
                    <b-form-input
                      v-model="rowData.overseas_bank_account_holder_name"
                      size="sm"
                      :disabled="!isEditing"
                      placeholder="Account Holder Name"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="4"
                >
                  <b-form-group>
                    <label>Bank Account Number</label>
                    <b-input-group>
                      <b-form-input
                        v-model="rowData.overseas_bank_account_no"
                        size="sm"
                        :disabled="!isEditing"
                        placeholder="Bank Account Number"
                      />
                      <template #append>
                        <b-button
                          size="sm"
                          @click="toggleCheckBankModal"
                        >
                          CHECK
                        </b-button>
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col><b-col
                  cols="12"
                  lg="8"
                >
                  <b-form-group>
                    <label>Bank Address</label>
                    <b-form-input
                      v-model="rowData.overseas_bank_address"
                      size="sm"
                      :disabled="!isEditing"
                      placeholder="Bank Address"
                    />
                  </b-form-group>
                </b-col>
              </template>
            </b-row>

          </div>
        </div>
        <b-row class="mt-3">
          <b-col>
            <b-button
              class="mr-1"
              @click="enableEdit"
            >
              Edit
            </b-button>
            <b-button
              v-if="isEditing"
              variant="primary"
              class="mr-1"
              @click="postSubmitForm"
            >
              Update Form
            </b-button>

            <b-button
              class="mr-1"
              @click="submitOnboarding"
            >Submit Form</b-button>

          </b-col>

        </b-row>
      </b-col>
      <b-col cols="3">
        <b-row class="mb-1">
          <b-col>

            <h2>
              <template v-if="rowData.onboarding_submission_state_id === 1">
                <b-badge variant="warning">
                  Pending Submission
                </b-badge>
              </template>
              <template v-else-if="rowData.onboarding_submission_state_id === 2">
                <b-badge variant="info">
                  Pending Approval
                </b-badge>
              </template>
              <template v-else-if="rowData.onboarding_submission_state_id === 3">
                <b-badge variant="success">
                  Details Approved
                </b-badge>
              </template>
              <template v-else-if="rowData.onboarding_submission_state_id === 4">
                <b-badge variant="danger">
                  Details Rejected
                </b-badge>
              </template>
              <template v-else-if="rowData.onboarding_submission_state_id === 5">
                <b-badge variant="info">
                  Verify Agreement
                </b-badge>
              </template>
              <template v-else-if="rowData.onboarding_submission_state_id === 6">
                <b-badge variant="success">
                  Approved Agreement
                </b-badge>
              </template>
              <template v-else-if="rowData.onboarding_submission_state_id === 7">
                <b-badge variant="danger">
                  Rejected Agreement
                </b-badge>
              </template>
              <template v-else-if="rowData.onboarding_submission_state_id === 8">
                <b-badge variant="success">
                  Activated Account
                </b-badge>
              </template>
              <template v-else>
                <b-badge>
                  {{ rowData.onboarding_submission_state }}
                </b-badge>

              </template>
            </h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group>
              <label>Merchant ID</label>
              <b-form-input
                v-model="rowData.merchant_id"
                size="sm"
                disabled
                placeholder="Merchant Detail ID"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <label>Merchant Type</label>
              <b-form-input
                v-model="rowData.merchant_type_id"
                size="sm"
                disabled
                placeholder="Merchant Detail ID"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <label>Merchant Detail ID</label>
              <b-form-input
                v-model="rowData.merchant_detail_id"
                size="sm"
                disabled
                placeholder="Merchant Detail ID"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group>
              <label>Onboarding State ID</label>

              <b-form-input
                v-model="rowData.onboarding_state_id"
                size="sm"
                disabled
                placeholder="Onboarding State ID"
              />
            </b-form-group>
          </b-col>

          <template v-if="rowData.onboarding_state_id === 2">
            <b-col cols="6">
              <b-form-group>
                <label>Approved At</label>
                <b-form-input
                  v-model="rowData.merchant_approved_at"
                  size="sm"
                  disabled
                  placeholder="Approved At"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <label>MAM Merchant Approved ID</label>
                <b-form-input
                  v-model="rowData.merchant_approved_id_from_mam"
                  size="sm"
                  disabled
                  placeholder="MAM Merchant Approved ID"
                />
              </b-form-group>
            </b-col>
          </template>

          <template v-if="rowData.onboarding_state_id === 3">
            <b-col cols="6">
              <b-form-group>
                <label>Rejected At</label>
                <b-form-input
                  v-model="rowData.merchant_rejected_at"
                  size="sm"
                  disabled
                  placeholder="Rejected At"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <label>MAM Merchant Rejected ID</label>
                <b-form-input
                  v-model="rowData.merchant_rejected_id_from_mam"
                  size="sm"
                  disabled
                  placeholder="MAM Merchant Rejected ID"
                />
              </b-form-group>
            </b-col>
          </template>

        </b-row>

        <b-row>
          <b-col>
            <b-form-group>
              <label>Referrer</label>
              <v-select
                v-model="referrer"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="company_name"
                placeholder="Please select referrer"
                :options="merchantReferrerListOpts"

                @option:selected="referChanged"
              />
            </b-form-group>

          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group>
              <label>Referral Key</label>
              <v-select
                v-model="rowData.referral_key"
                :reduce="referral_key => referral_key.value"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                placeholder="Select Referral Key"
                :options="merchantReferralKeyListOpts"
              />
            </b-form-group>

          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col>
            <b-button
              variant="primary"
              class="mr-1"
              @click="submitOnboardingReferralPlanSettingsAdmin"
            >
              Update Referral and plan settings
            </b-button>
          </b-col>
        </b-row>

        <b-row class="mt-3">

          <b-col
            v-if="rowData.agreement_before_sign"
            cols="2"
          >
            <a
              class="btn"
              :href="rowData.agreement_before_sign"
              target="_blank"
            ><b-img
              :src="pdfIconImage"
              width="30"
            /></a>
          </b-col>
          <b-col>
            <h6 class="font-weight-bolder">
              Upload Agreement File (Unsigned)
            </h6>
            <b-row>
              <b-col>
                <b-form-group>
                  <b-form-file
                    v-model="merchantAgreementFile"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="auto">
                <b-button
                  @click="uploadAgreementFile"
                >
                  Upload
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-2">

          <b-col
            v-if="rowData.agreement_document_link"
            cols="2"
          >
            <a
              class="btn"
              :href="rowData.agreement_document_link"
              target="_blank"
            ><b-img
              :src="pdfIconImage"
              width="30"
            /></a>
          </b-col>
          <b-col>
            <h6 class="font-weight-bolder">
              Upload Agreement (Signed)
            </h6>
            <b-row>
              <b-col>
                <b-form-group>
                  <b-form-file
                    v-model="merchantDocumentAgreementFile"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="auto">
                <b-button
                  @click="uploadDocument('agreement')"
                >
                  Upload
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col
            v-if="rowData.appendix_one_link"
            cols="2"
          >
            <a
              class="btn"
              :href="rowData.appendix_one_link"
              target="_blank"
            ><b-img
              :src="pdfIconImage"
              width="30"
            /></a>
          </b-col>
          <b-col>
            <h6 class="font-weight-bolder">
              Upload Certificate of Incorporation
            </h6>
            <b-row>
              <b-col>
                <b-form-group>
                  <b-form-file
                    v-model="merchantDocumentAppendixOneFile"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="auto">
                <b-button
                  @click="uploadDocument('appendix_one', 'Certificate of Incorporation')"
                >
                  Upload
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-1">

          <b-col
            v-if="rowData.appendix_two_link"
            cols="2"
          >
            <a
              class="btn"
              :href="rowData.appendix_two_link"
              target="_blank"
            ><b-img
              :src="pdfIconImage"
              width="30"
            /></a>
          </b-col>
          <b-col>
            <h6 class="font-weight-bolder">
              Upload Bank Letter
            </h6>
            <b-row>
              <b-col>
                <b-form-group>
                  <b-form-file
                    v-model="merchantDocumentAppendixTwoFile"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="auto">
                <b-button
                  @click="uploadDocument('appendix_two', 'Bank Letter')"
                >
                  Upload
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col
            v-if="rowData.appendix_three_link"
            cols="2"
          >
            <a
              class="btn"
              :href="rowData.appendix_three_link"
              target="_blank"
            ><b-img
              :src="pdfIconImage"
              width="30"
            /></a>
          </b-col>
          <b-col>
            <h6 class="font-weight-bolder">
              Upload Proof Of Address
            </h6>
            <b-row>
              <b-col>
                <b-form-group>
                  <b-form-file
                    v-model="merchantDocumentAppendixThreeFile"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="auto">
                <b-button
                  @click="uploadDocument('appendix_three', 'Proof Of Address')"
                >
                  Upload
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col
            v-if="rowData.appendix_four_link"
            cols="2"
          >
            <a
              class="btn"
              :href="rowData.appendix_four_link"
              target="_blank"
            ><b-img
              :src="pdfIconImage"
              width="30"
            /></a>
          </b-col>
          <b-col>
            <h6 class="font-weight-bolder">
              Upload Passport Image
            </h6>
            <b-row>
              <b-col>
                <b-form-group>
                  <b-form-file
                    v-model="merchantDocumentAppendixFourFile"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="auto">
                <b-button
                  @click="uploadDocument('appendix_four', 'Passport Image')"
                >
                  Upload
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col
            v-if="rowData.appendix_five_link"
            cols="2"
          >
            <a
              class="btn"
              :href="rowData.appendix_five_link"
              target="_blank"
            ><b-img
              :src="pdfIconImage"
              width="30"
            />
            </a>
          </b-col>
          <b-col>
            <h6 class="font-weight-bolder">
              Upload List of Shareholder
            </h6>
            <b-row>
              <b-col>
                <b-form-group>
                  <b-form-file
                    v-model="merchantDocumentAppendixFiveFile"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="auto">
                <b-button
                  @click="uploadDocument('appendix_five', 'List of Shareholder')"
                >
                  Upload
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <b-button @click="submitOnboardingDocument()">
              Confirm Documents
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  find, map, merge, sortBy,
} from 'lodash'
import vSelect from 'vue-select'

export default {
  components: { vSelect },
  props: {
    rowData: {
      type: Object,
      required: true,
      default() {
        return {
          id: null,
          merchant_detail_id: null,
          full_name: null,
          email: '',
          phone_number: '',
          plan: null,
          referral_key: null,

          company_name: null,
          company_registration_no: null,
          company_nature_of_business: null,
          company_description_of_product: null,
          company_business_address_1: null,
          company_business_address_2: null,
          company_business_postcode: null,
          company_business_state: null,
          company_business_city: null,
          payout_service_id: null,
          company_bank_name: null,
          company_bank_account_no: null,
          company_bank_account_name: null,
          signature_applicant_ic: null,
          merchant_id: '',
          affiliate_account_uuid: null,
          onboarding_product_offering_list: '',
          merchant_approved_at: null,
          merchant_approved_id_from_mam: null,
          merchant_rejected_at: null,
          merchant_rejected_id_from_mam: null,
          onboarding_state_id: null,
          duns_no: '',
          company_business_country: null,
        }
      },
    },
  },
  data() {
    return {
      pdfIconImage: require('@/assets/images/icons/filetype-icons/pdf.png'),
      messageResponse: '',
      referrer: {
        company_name: 'Please Select',
        affiliate_account_uuid: null,
        affiliate_code: null,
        mam_merchant_id: null,
      },
      showMessage: false,
      isEditing: false,
      collapseMode: false,
      selectedOnboardingProductOfferingList: [],
      selectedOnboardingPlan: null,
      merchantAgreementFile: null,
      merchantDocumentAgreementFile: null,
      merchantDocumentAppendixOneFile: null,
      merchantDocumentAppendixFourFile: null,
      merchantDocumentAppendixTwoFile: null,
      merchantDocumentAppendixThreeFile: null,
      merchantDocumentAppendixFiveFile: null,

      planSelection: [
        {
          title: 'Basic',
          desc: 'Free',
          disabled: false,
          icon: { icon: 'custom-office', size: '40' },
          value: '1',
        },
        {
          title: 'Standard',
          desc: 'Monthly MYR 299',
          disabled: false,
          icon: { icon: 'custom-diamond', size: '40' },
          value: '2',
        },
        {
          enabled: true,
          disabled: true,
          title: 'Enterprise',
          desc: 'Monthly 899',
          value: '3',
          icon: { icon: 'custom-suitcase', size: '40' },

        },
      ],
      offerings: [
        {
          title: 'FPX',
          value: 1,
          order: 1,
          disabled: true,
          desc: 'Backup every file from your project.',
          icon: { icon: 'tabler-server-2', size: '28' },
        },
        {
          title: 'Credit Card',
          value: 2,
          order: 2,
          desc: 'Backup every file from your project.',
          icon: { icon: 'tabler-credit-card', size: '28' },
        },
        {
          title: 'Payout',
          value: 3,

          // disabled: true,
          order: 9,
          desc: 'Backup every file from your project.',
          icon: { icon: 'tabler-credit-card-pay', size: '28' },
        },
        {
          title: 'DuitNow',

          value: 4,
          order: 4,
          desc: 'Backup every file from your project.',
          icon: { icon: 'tabler-server-2', size: '28' },
        },
        {
          title: 'E-Wallet',
          value: 5,
          order: 5,
          desc: 'Backup every file from your project.',
          icon: { icon: 'tabler-wallet', size: '28' },
        },
        {
          title: 'PayPal',
          value: 6,
          order: 6,
          desc: 'Backup every file from your project.',
          icon: { icon: 'tabler-brand-paypal', size: '28' },
        },
        {
          title: 'Buy Now Pay Later',
          value: 7,
          order: 7,
          desc: 'Backup every file from your project.',
          icon: { icon: 'tabler-server-2', size: '28' },
        },
        {
          title: 'Cryptocurrency',
          value: 8,
          order: 8,
          desc: 'Backup every file from your project.',
          icon: { icon: 'tabler-currency-bitcoin', size: '28' },
        },
      ],
    }
  },
  computed: {
    emailVerified() {
      const checklist = this.rowData.onboardingChecklist

      if (Array.isArray(checklist)) {
        const emailChecklist = checklist.filter(item => item.onboarding_checklist === 'EMAIL_VERIFICATION')
        return emailChecklist[0].onboarding_checklist_status_id === 4
      } return false
    },
    merchantReferrerListOpts() {
      const list = this.$store.getters['onboardingv2/getReferrerMerchantListOpts']
      if (Array.isArray(list) && list.length > 0) {
        return list.map(item => ({
          company_name: item.company_name,
          merchant_id: item.merchant_id,
          affiliate_uuid: item.affiliate_uuid,
          affiliate_code: item.affiliate_code,
        }))
      } return []
    },
    merchantReferralKeyListOpts() {
      const list = this.$store.getters['referral/referralList']
      if (Array.isArray(list) && list.length > 0) {
        return list.map(item => ({
          name: item.Subscription.plan_name,
          value: item.referral_key,
        }))
      } return []
    },
    MerchantDetailsAllowPost() {
      return this.rowData.onboarding_submission_state_id === 1
    },
    planList() {
      let filteredPlan

      if (!this.MerchantDetailsAllowPost) filteredPlan = this.planSelection.map(v => ({ ...v, disabled: true }))

      else filteredPlan = this.planSelection

      return filteredPlan
    },

    PayoutServiceList() {
      const payoutServiceList = this.$store.getters['onboardingv2/getPayoutBankChannelList']
      if (payoutServiceList) {
        return payoutServiceList
      } return []
    },
    productOfferingList() {
      const OnboardingProductOfferingReference = this.$store.getters['systemConstants/getSystemConstantsByKey']('OnboardingProductOfferingReference')

      if (OnboardingProductOfferingReference) {
        let filteredOfferings

        if (!this.MerchantDetailsAllowPost) filteredOfferings = this.offerings.map(v => ({ ...v, disabled: true }))

        else filteredOfferings = this.offerings

        console.log('filteredOfferings', this.offerings.map(v => ({ ...v, disabled: true })))

        return sortBy(map(filteredOfferings, item => {
          const merged = merge(item, find(OnboardingProductOfferingReference, { value: item.value }))

          console.log('merged', merged)

          return merged
        }), 'order')
      }

      return {}
    },
  },
  watch: {
    referrer(value) {
      if (!value) {
        this.rowData.affiliate_account_uuid = null
        this.rowData.affiliate_code = null
      }
    },
    'rowData.referral_key': function (newVal, oldVal) {
      if (!newVal) {
        this.rowData.referral_key = null
      }
    },
  },
  async mounted() {
    // await this.processOfferingsList()
    await this.getReferrerMerchantList()
    const payload = {
      limit: 1000,
      skip: 0,
      record_status: 1,
      search: {
        search_enable: false,
        search_key: '',
        search_column: '',
        search_replace_word_enable: true,
        search_word_replace: ' ',
        search_word_replace_to: '_',
      },
      sort: {
        parameter_name: 'created_at',
        sort_type: 'desc',
      },
    }
    await this.$store.dispatch('referral/getAdminReferralsList', payload)

    if (this.rowData.referrer) {
      this.referrer = {
        company_name: this.rowData.referrer.mam_account_name,
        affiliate_uuid: this.rowData.referrer.affiliate_uuid,
        affiliate_code: this.rowData.referrer.affiliate_code,
        merchant_id: this.rowData.referrer.merchant_id,
      }
    }
  },
  methods: {
    sendVerificationEmail() {
      const payload = {
        merchant_detail_id: this.rowData.merchant_detail_id,
      }

      this.$store.dispatch('onboardingv2/sendEMailVerificationLinkViaAdmin', payload).then(resp => {
        if (resp.data.response_code === 2100) {
          this.$swal.fire({
            title: 'Successfully Sent',
            text: 'Email verification has been successfully sent',
            icon: 'success',
          })
        } else {
          this.$swal.fire({
            title: `Failed ${resp.data.response_code}`,
            text: resp.data.description,
            icon: 'error',
          })
        }

        this.$emit('refresh-table', true)
      })
    },
    referChanged($e) {
      this.rowData.affiliate_code = $e.affiliate_code
      this.rowData.affiliate_account_uuid = $e.affiliate_uuid
      this.rowData.referrer_merchant_id = $e.merchant_id
    },
    async getReferrerMerchantList() {
      const payload = {
        length: 1000,
        start: 0,
        filter_array_objects: JSON.stringify([
          {
            filter_column: 'created_at',
            filter_start: '1945-02-01',
            filter_end: '2045-02-27',
          },
          {
            filter_column: 'keyword',
            filter_value: '',
          },
        ]),
      }
      await this.$store.dispatch('onboardingv2/getReferrerMerchantList', payload)
    },
    populatePayoutServiceIdField(e) {
      this.rowData.payout_service_id = e.payout_service_id
    },
    uploadAgreementFile() {
      const formData = new FormData()

      if (!this.merchantAgreementFile) {
        this.$swal.fire({
          title: 'File not added',
          text: 'File is required',
          icon: 'error',
        })
        return
      }

      formData.append('merchant_detail_id', this.rowData.merchant_detail_id)
      formData.append('merchant_agreement', this.merchantAgreementFile)

      this.$store.dispatch('onboardingv2/uploadMerchantAgreement', formData).then(res => {
        if (res.data.response_code === 2100) {
          this.$swal.fire({
            title: 'Successfully Uploaded',
            text: 'Merchant agreement have been successfully uploaded',
            icon: 'success',
          })
        } else {
          this.$swal.fire({
            title: `Failed ${res.data.response_code}`,
            text: res.data.description,
            icon: 'error',
          })
        }

        this.$emit('refresh-table', true)
      })
    },
    uploadDocument(type, label = null) {
      const formData = new FormData()
      if (type === 'appendix_one' && !this.merchantDocumentAppendixOneFile) {
        this.$swal.fire({
          title: 'File not added',
          text: 'File is required',
          icon: 'error',
        })
        return
      }
      if (type === 'appendix_two' && !this.merchantDocumentAppendixTwoFile) {
        this.$swal.fire({
          title: 'File not added',
          text: 'File is required',
          icon: 'error',
        })
        return
      }
      if (type === 'appendix_three' && !this.merchantDocumentAppendixThreeFile) {
        this.$swal.fire({
          title: 'File not added',
          text: 'File is required',
          icon: 'error',
        })
        return
      }
      if (type === 'appendix_four' && !this.merchantDocumentAppendixFourFile) {
        this.$swal.fire({
          title: 'File not added',
          text: 'File is required',
          icon: 'error',
        })
        return
      }
      if (type === 'appendix_five' && !this.merchantDocumentAppendixFiveFile) {
        this.$swal.fire({
          title: 'File not added',
          text: 'File is required',
          icon: 'error',
        })
        return
      }
      if (type === 'agreement' && !this.merchantDocumentAgreementFile) {
        this.$swal.fire({
          title: 'File not added',
          text: 'File is required',
          icon: 'error',
        })
        return
      }

      formData.append('merchant_detail_id', this.rowData.merchant_detail_id)
      if (type === 'appendix_one') formData.append('appendix_one', this.merchantDocumentAppendixOneFile)
      if (type === 'appendix_two') formData.append('appendix_two', this.merchantDocumentAppendixTwoFile)
      if (type === 'appendix_three') formData.append('appendix_three', this.merchantDocumentAppendixThreeFile)
      if (type === 'appendix_four') formData.append('appendix_four', this.merchantDocumentAppendixFourFile)
      if (type === 'appendix_five') formData.append('appendix_five', this.merchantDocumentAppendixFiveFile)
      if (type === 'agreement') formData.append('agreement', this.merchantDocumentAgreementFile)

      let alertLabel
      if (label) {
        alertLabel = label
      } else {
        alertLabel = type
      }
      this.$store.dispatch('onboardingv2/uploadMerchantDocumentsOnBehalf', formData).then(res => {
        if (res.data.response_code === 2100) {
          this.$swal.fire({
            title: 'Successfully Uploaded',
            text: `Merchant ${alertLabel} have been successfully uploaded`,
            icon: 'success',
          })
        } else {
          this.$swal.fire({
            title: `Failed ${res.data.response_code}`,
            text: res.data.description,
            icon: 'error',
          })
        }

        this.$emit('refresh-table', true)
      })
    },
    async processOfferingsList() {
      if (this.rowData && this.rowData.onboarding_product_offering_list && this.rowData.onboarding_product_offering_list.length === 0) {
        // alert('hai2')
        this.selectedOnboardingProductOfferingList = []
      }
      // if (this.rowData.onboarding_product_offering_list.length === 1) {
      //   this.selectedOnboardingProductOfferingList = [this.rowData.onboarding_product_offering_list]
      //   this.selectedOnboardingProductOfferingList.forEach(item => parseInt(item, 10))
      // }
      if (this.rowData && this.rowData.onboarding_product_offering_list && this.rowData.onboarding_product_offering_list.length > 1) {
        // console.log(this.rowData.onboarding_product_offering_list)
        // alert(`hai${this.rowData.onboarding_product_offering_list}`)
        this.selectedOnboardingProductOfferingList = this.rowData.onboarding_product_offering_list.split(',')

        this.selectedOnboardingProductOfferingList = this.selectedOnboardingProductOfferingList.map(item => parseInt(item, 10))
      }
      // if (this.rowData && this.rowData.plan) {
      //   this.rowData.plan = parseInt(this.rowData.plan, 10)
      // }
    },
    enableEdit() {
      if (this.rowData.onboarding_state_id !== 2) {
        if (this.isEditing) {
          this.isEditing = !this.isEditing
        } else {
          this.isEditing = true
        }
      }
    },
    postSubmitForm() {
      const payload = {
        merchant_onboarding_info: {
          ...this.rowData,
          onboarding_product_offering_list: this.selectedOnboardingProductOfferingList.toString(),
        },
      }
      if (this.rowData.onboarding_origin_reference_id === 1) {
        this.$store.dispatch('onboardingv2/postOnboardingSubmissionDetailForAdmin', payload).then(resp => {
          if (resp.data.response_code === 2100) {
            this.showMessage = true
            this.messageResponse = {
              errorCode: resp.data.response_code,
              message: 'Successfully updated',
            }
          } else {
            this.showMessage = true
            this.messageResponse = {
              errorCode: resp.data.response_code,
              message: `Update Unsuccessful (${resp.data.description} - ${resp.data.response_code})`,
            }
          }

          this.$emit('refresh-table', true)
        })
      }

      if (this.rowData.onboarding_origin_reference_id === 2) {
        this.$store.dispatch('onboardingv2/postOnboardingSubmissionDetailForAdminOverseas', payload).then(resp => {
          if (resp.data.response_code === 2100) {
            this.showMessage = true
            this.messageResponse = {
              errorCode: resp.data.response_code,
              message: 'Successfully updated',
            }
          } else {
            this.showMessage = true
            this.messageResponse = {
              errorCode: resp.data.response_code,
              message: `Update Unsuccessful (${resp.data.description} - ${resp.data.response_code})`,
            }
          }
        })

        this.$emit('refresh-table', true)
      }
    },

    submitOnboardingReferralPlanSettingsAdmin() {
      const payload = {
        merchant_onboarding_info: {
          ...this.rowData,
          onboarding_product_offering_list: this.selectedOnboardingProductOfferingList.toString(),
        },
      }

      this.$store.dispatch('onboardingv2/submitOnboardingReferralPlanSettingsAdmin', payload).then(resp => {
        if (resp.data.response_code === 2100) {
          this.$swal.fire({
            title: 'Successfully Submitted',
            text: 'Merchant successfully submitted',
            icon: 'success',
          })
        } else {
          this.$swal.fire({
            title: `Failed ${resp.data.response_code}`,
            text: resp.data.description,
            icon: 'error',
          })
        }

        this.$emit('refresh-table', true)
      })
    },

    submitOnboarding() {
      const payload = {
        merchant_detail_id: this.rowData.merchant_detail_id,
      }

      if (this.rowData.onboarding_origin_reference_id === 1) {
        this.$store.dispatch('onboardingv2/submitOnboardingSubmissionForAdmin', payload).then(resp => {
          if (resp.data.response_code === 2100) {
            this.$swal.fire({
              title: 'Successfully Submitted',
              text: 'Merchant successfully submitted',
              icon: 'success',
            })
          } else {
            this.$swal.fire({
              title: `Failed ${resp.data.response_code}`,
              text: resp.data.description,
              icon: 'error',
            })
          }
        })

        this.$emit('refresh-table', true)
      }

      if (this.rowData.onboarding_origin_reference_id === 2) {
        this.$store.dispatch('onboardingv2/submitOnboardingSubmissionForAdminOverseas', payload).then(resp => {
          if (resp.data.response_code === 2100) {
            this.$swal.fire({
              title: 'Successfully Submitted',
              text: 'Merchant successfully submitted',
              icon: 'success',
            })
          } else {
            this.$swal.fire({
              title: `Failed ${resp.data.response_code}`,
              text: resp.data.description,
              icon: 'error',
            })
          }

          this.$emit('refresh-table', true)
        })
      }
    },

    submitOnboardingDocument() {
      const payload = {
        merchant_detail_id: this.rowData.merchant_detail_id,
      }

      if (this.rowData.onboarding_origin_reference_id === 1) {
        this.$store.dispatch('onboardingv2/submitOnboardingAgreementMalaysiaForAdmin', payload).then(resp => {
          if (resp.data.response_code === 2100) {
            this.$swal.fire({
              title: 'Successfully Submitted Document',
              text: 'Merchant Document successfully submitted',
              icon: 'success',
            })
          } else {
            this.$swal.fire({
              title: `Failed ${resp.data.response_code}`,
              text: resp.data.description,
              icon: 'error',
            })
          }

          this.$emit('refresh-table', true)
        })
      }

      if (this.rowData.onboarding_origin_reference_id === 2) {
        this.$store.dispatch('onboardingv2/submitOnboardingAgreementOverseaForAdmin', payload).then(resp => {
          if (resp.data.response_code === 2100) {
            this.$swal.fire({
              title: 'Successfully Submitted Document',
              text: 'Merchant Document successfully submitted',
              icon: 'success',
            })
          } else {
            this.$swal.fire({
              title: `Failed ${resp.data.response_code}`,
              text: resp.data.description,
              icon: 'error',
            })
          }

          this.$emit('refresh-table', true)
        })
      }
    },
  },
}
</script>

<style>
.vs--disabled.product-offering-vselect .vs__selected {
  background-color: #aaa;
}
</style>
